import {
    useLocation,
    Navigate,
    useParams,
    useNavigate
} from "react-router-dom";
import Assistant from "../pages/assistant/assistant";
import Qr from "../pages/Qr";
import ConfigEvent from "../pages/configuration/config.event";
import _service from './service';

class Auth {


    token = null;
    user = null;

    constructor() {
        this.token = localStorage.getItem("tokendigitizer");
        let user = localStorage.getItem("tokenUserInexmoda");
        if (user) {
            this.user = JSON.parse(user);
        }
    }

    login(token, user, callBack) {
        this.token = token;
        // this.user = this.parseJwt(this.token);
        this.user = user;
        localStorage.setItem("tokenUserInexmoda", JSON.stringify(user));
        localStorage.setItem("tokendigitizer", token);
        callBack();
    }


    logout(callBack) {
        localStorage.clear();
        this.token = null;
        this.user = null;
        callBack();
    }

    isAutenticated() {
        return this.token ? true : false;
    }

    parseJwt(token) {
        if (!token)
            return null;

        var base64Url = token.split('.')[0];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    };

    RequireAuth = ({ children }) => {
        let location = useLocation();
        if (!this.token) {
            return <Navigate to="/login" state={{ from: location }} replace />;
        }
        return children;
    }

    RequireLogin = ({ children }) => {
        let location = useLocation();

        if (this.token) {
            return <Navigate to="/tabs/assistant" state={{ from: location }} replace />;
        }
        return children;
    }


    RequireLoginValidation = ({ children }) => {
        let location = useLocation();
        if (this.token) {
            return <Navigate to="/tabs/assistant" state={{ from: location }} replace />;
        }
        return <Navigate to="/login" state={{ from: location }} replace />;
    }



    redirectQR = () => {
        let params = useParams();
        let navigate = useNavigate();
        return <Qr navigate={navigate} params={params} />;
    }

    assistantEvent = () => {
        let params = useParams();
        let navigate = useNavigate();
        return <Assistant navigate={navigate} params={params} />;
    }

    ConfigEventFuntion = () => {
        let params = useParams();
        let navigate = useNavigate();
        return <ConfigEvent navigate={navigate} params={params} />;
    }


    logaunt = (cb) => {
        this.closeRemove();
        cb();
    }

    closeRemove() {
        this.user = null;
        this.token = null;
        localStorage.clear();
    }

    logaunt2 = () => {
        if (this.token)
            // _service.close().then(resp => { this.closeRemove() }).catch(error => { this.closeRemove() });
            this.closeRemove();
        else {
            this.closeRemove();
        }
        window.location.reload();
    }


}
export default new Auth();