import React, { Component } from 'react'
import './login.css';
import _service from '../../service/service';
import auth from '../../service/auth';

class Login extends Component {

    state = {
        changeLeng: false,
        loading: false,
        user: {},
        errors: {},
        sendTab: false
    }


    constructor(props) {
        super(props);
        this.state = {
            changeLeng: false,
            loading: false,
            user: {},
            errors: {},
            sendTab: false,
            servers: [
                {
                    name: 'Servidor principal (Google)',
                    url: 'https://coltex.aplicacionesincontacto.com/inexmodapi'
                },
                {
                    name: 'Servidor Mercury',
                    url: 'https://coltex1.aplicacionesincontacto.com/inexmodapi'
                },
                {
                    name: 'Servidor 2 (Zeus)',
                    url: 'https://aplicacionesincontacto.com:48080/inexmodapi'
                }
                // ,
                // {
                //     name: 'Inexmoda Pruebas',
                //     url: 'https://aplicacionesincontacto.com:48080/inexmodapitest'
                // }
            ],
            changeServer: false
        };
        this.init();
    }

    init() {
        setTimeout(() => {
            let ipserver = _service.getIpServer();
            this.setState({
                user: {
                    ipserver: ipserver
                }
            })
        }, 500);

    }

    clickLogo = () => {
        let changeServer = !this.state.changeServer;
        this.setState({
            changeServer: changeServer
        })
    }


    save = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        _service.login(this.state.user.username, this.state.user.password)
            .then(resp => {
                console.log(resp);
                auth.login(resp.token, resp.user, () => {
                    this.props.navigate("/tabs/assistant")
                    this.setState({ loading: false, sendTab: true });
                });
                this.setState({ loading: false });
            })
            .catch(err => this.setState({ loading: false }));
    }

    handleChange = async    (props, event) => {
        const { value, name } = event.target;
        if (name === props) {

            if (props == 'ipserver' && value) {
             await   localStorage.setItem('ipserver', value);
            }


            if (!value) {
                let datAerror = { errors: { ...this.state.errors }, user: { ...this.state.user } };
                datAerror.errors[props] = true;
                datAerror.user[props] = "";
                this.setState(datAerror);
            } else {
                let datAerror = { errors: { ...this.state.errors }, user: { ...this.state.user } };
                datAerror.errors[props] = false;
                datAerror.user[props] = value;
                this.setState(datAerror);
            }
           
        }


        
    }

    forGet() {
    }
    render() {
        return (


            <div>
                <div className="fondo-login">
                </div>

                <div className='fondo-login-2'></div>



                <div className="content-login">

                    <div className="col-md-6 p-5">
                        <div className="card card-personalizatioin-login">
                            <div className="card-body position-relative">

                                <div className="content-icon">
                                    <div className="icon-position" onClick={this.clickLogo.bind(this)}>
                                        <img src="https://aplicacionesincontacto.com/inexmoda/assets/images/logo-inexmoda-2.png" alt="Logo Feriale" />
                                    </div>
                                </div>



                                <div className="content-option">
                                    <div className="text-center">
                                        <h5>
                                            Registro Inexmoda
                                        </h5>
                                        <h6 className="text-muted">
                                            <small>
                                                Ingrese su nombre de usuario y contraseña
                                            </small>
                                        </h6>
                                        <div className='center-component'>
                                            <div className="hr-personalization">
                                            </div>
                                        </div>
                                    </div>

                                    <form
                                        name="loginForm"
                                        onSubmit={this.save.bind(this)}
                                    >

                                        {
                                            this.state.changeServer &&

                                            <select className='form-control mt-3' id="ipserver" name="ipserver"
                                                value={this.state.user["ipserver"]}
                                                onChange={this.handleChange.bind(this, "ipserver")}
                                            >

                                                <option>Seleccione el servidor espejo</option>
                                                {
                                                    this.state.servers.map((item, index) => {
                                                        return <option key={index + 'optionserver'} value={item.url}  >{item.name}</option>;
                                                    })
                                                }
                                            </select>
                                        }



                                        <input type="text" id="username" name="username"
                                            refs="username"
                                            placeholder="Usuario" required
                                            value={this.state.user["email"]}
                                            onChange={this.handleChange.bind(this, "username")}
                                        />
                                        {
                                            this.state.errors["username"] ? (<div className='error-field'>
                                                * El usuario es requerido
                                            </div>) : (<div>

                                            </div>)
                                        }

                                        <input type="password" id="password" name="password"
                                            refs="password"
                                            placeholder="Constraseña" required
                                            value={this.state.user["password"]}
                                            onChange={this.handleChange.bind(this, "password")}
                                        />
                                        {
                                            this.state.errors["password"] ? (<div className='error-field'>
                                                * La contrasela es requeido
                                            </div>) : (<div>

                                            </div>)
                                        }

                                        <a onClick={() => this.forGet()}> ¿Se te olvidó tu contraseña?</a>
                                        <button type="submit" disabled={this.state.loading} >
                                            {this.state.loading ? (
                                                <span>
                                                    Iniciando...
                                                </span>
                                            ) : (
                                                <span>
                                                    Iniciar sesión
                                                </span>
                                            )
                                            }
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div >
                    </div >
                </div >
            </div >

        )
    }
}

export default Login;