import React, { Component } from "react";
import _service from '../../service/service';
import { Form, Button } from 'react-bootstrap';
import Swal from "sweetalert2";

export default class FieldsAssistant extends Component {


    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            assistant: {},
            activities: [],
            optionsSegmentOfInterests: [],
            optionsCategoryCurrents: [],
            optionsCategoryCurrentsCOLMODA:[],
            saving: false,
            categories: [],
            messageLoader: "Guardando...."
        }
    }

    componentDidMount() {
        let activities = [];
        activities.push({ name: 'CONFECCIONISTA / MARCA PROPIA', nameview: 'CONFECCIONISTA / MARCA PROPIA', translate: 'FORM.OIPTIONACTIVITY.OPTION1' });
        activities.push({ name: 'COMERCIALIZADOR', nameview: 'COMERCIALIZADOR', translate: 'FORM.OIPTIONACTIVITY.OPTION2' });
        activities.push({ name: 'DISTRIBUIDOR / REPRESENTANTE', nameview: 'DISTRIBUIDOR / REPRESENTANTE', translate: 'FORM.OIPTIONACTIVITY.OPTION3' });
        activities.push({ name: 'PAQUETE COMPLETO', nameview: 'PAQUETE COMPLETO', translate: 'FORM.OIPTIONACTIVITY.OPTION4' });
        activities.push({ name: 'SERVICIOS DE TINTORERÍA Y LAVANDERÍA', nameview: 'SERVICIOS DE TINTORERÍA Y LAVANDERÍA', translate: 'FORM.OIPTIONACTIVITY.OPTION5' });
        activities.push({ name: 'SERVICIOS DE ACABADOS', nameview: 'SERVICIOS DE ACABADOS', translate: 'FORM.OIPTIONACTIVITY.OPTION6' });
        activities.push({ name: 'BOUTIQUES / MULTIMARCA', nameview: 'BOUTIQUES / MULTIMARCA', translate: 'FORM.OIPTIONACTIVITY.OPTION7' });
        activities.push({ name: 'PLATAFORMA EN LÍNEA', nameview: 'PLATAFORMA EN LÍNEA', translate: 'FORM.OIPTIONACTIVITY.OPTION8' });
        activities.push({ name: 'VENTA POR CATALOGO', nameview: 'VENTA POR CATALOGO', translate: 'FORM.OIPTIONACTIVITY.OPTION9' });
        activities.push({ name: 'TIENDA POR DEPARTAMENTO (GRANDES SUPERFICIES)', nameview: 'TIENDA POR DEPARTAMENTO (GRANDES SUPERFICIES)', translate: 'FORM.OIPTIONACTIVITY.OPTION10' });


        let optionsSegmentOfInterests = [];
        optionsSegmentOfInterests.push({ name: 'MASCULINO', nameview: 'MASCULINO', translate: 'FORM.OPTIONSEGMENTOFINTEREST.OPTION1' });
        optionsSegmentOfInterests.push({ name: 'FEMENINO', nameview: 'FEMENINO', translate: 'FORM.OPTIONSEGMENTOFINTEREST.OPTION2' });
        optionsSegmentOfInterests.push({ name: 'INFANTIL', nameview: 'INFANTIL', translate: 'FORM.OPTIONSEGMENTOFINTEREST.OPTION3' });
        optionsSegmentOfInterests.push({ name: 'MIXTO (FEMENINO, MASCULINO E INFANTIL)', nameview: 'MIXTO (FEMENINO, MASCULINO E INFANTIL)', translate: 'FORM.OPTIONSEGMENTOFINTEREST.OPTION4' });
        optionsSegmentOfInterests.push({ name: 'HOGAR', nameview: 'HOGAR', translate: 'FORM.OPTIONSEGMENTOFINTEREST.OPTION5' });
        optionsSegmentOfInterests.push({ name: 'MASCOTAS', nameview: 'MASCOTAS', translate: 'FORM.OPTIONSEGMENTOFINTEREST.OPTION6' });
        optionsSegmentOfInterests.push({ name: 'SELLO DE AUTOR', nameview: 'SELLO DE AUTOR', translate: 'FORM.OPTIONSEGMENTOFINTEREST.OPTION7' });
        optionsSegmentOfInterests.push({ name: 'MODA CONCIENTE', nameview: 'MODA CONCIENTE', translate: 'FORM.OPTIONSEGMENTOFINTEREST.OPTION8' });
        optionsSegmentOfInterests.push({ name: 'SALUD/ PROTECCIÓN', nameview: 'SALUD/ PROTECCIÓN', isother: true, translate: 'FORM.OPTIONSEGMENTOFINTEREST.OPTION9' });



        let optionsCategoryCurrents = [];
        optionsCategoryCurrents.push({ name: 'FIBRAS TEXTILES', nameview: 'FIBRAS TEXTILES', translate: 'FORM.OPTIONCATEGORYCURRENT.OPTION1' });
        optionsCategoryCurrents.push({ name: 'HILOS E HILAZAS', nameview: 'HILOS E HILAZAS', translate: 'FORM.OPTIONCATEGORYCURRENT.OPTION2' });
        optionsCategoryCurrents.push({ name: 'TEXTILES', nameview: 'TEXTILES', translate: 'FORM.OPTIONCATEGORYCURRENT.OPTION3' });
        optionsCategoryCurrents.push({ name: 'MAQUINARIA', nameview: 'MAQUINARIA', translate: 'FORM.OPTIONCATEGORYCURRENT.OPTION4' });
        optionsCategoryCurrents.push({ name: 'TEXTILES TÉCNICOS', nameview: 'TEXTILES TÉCNICOS', translate: 'FORM.OPTIONCATEGORYCURRENT.OPTION5' });
        optionsCategoryCurrents.push({ name: 'INSUMOS PARA LA CONFECCIÓN"', nameview: 'INSUMOS PARA LA CONFECCIÓN"', translate: 'FORM.OPTIONCATEGORYCURRENT.OPTION6' });
        optionsCategoryCurrents.push({ name: 'INSUMOS PARA LA INDUSTRIA TEXTIL', nameview: 'INSUMOS PARA LA INDUSTRIA TEXTIL', translate: 'FORM.OPTIONCATEGORYCURRENT.OPTION7' });
        optionsCategoryCurrents.push({ name: 'INSUMOS QUÍMICOS', nameview: 'INSUMOS QUÍMICOS', translate: 'FORM.OPTIONCATEGORYCURRENT.OPTION8' });
        optionsCategoryCurrents.push({ name: 'SERVICIOS DE TINTORERÍA', nameview: 'SERVICIOS DE TINTORERÍA', translate: 'FORM.OPTIONCATEGORYCURRENT.OPTION9' });
        optionsCategoryCurrents.push({ name: 'SERVICIOS DE  ACABADOS', nameview: 'SERVICIOS DE  ACABADOS', translate: 'FORM.OPTIONCATEGORYCURRENT.OPTION10' });
        optionsCategoryCurrents.push({ name: 'SUBLIMACIÓN', nameview: 'SUBLIMACIÓN', translate: 'FORM.OPTIONCATEGORYCURRENT.OPTION11' });
        optionsCategoryCurrents.push({ name: 'ESTAMPACIÓN', nameview: 'ESTAMPACIÓN', translate: 'FORM.OPTIONCATEGORYCURRENT.OPTION12' });
        optionsCategoryCurrents.push({ name: 'ESTAMPACIÓN DIGITAL', nameview: 'ESTAMPACIÓN DIGITAL', translate: 'FORM.OPTIONCATEGORYCURRENT.OPTION13' });
        optionsCategoryCurrents.push({ name: 'BORDADOS', nameview: 'BORDADOS', translate: 'FORM.OPTIONCATEGORYCURRENT.OPTION14' });
        optionsCategoryCurrents.push({ name: 'SERVICIOS DE LAVANDERÍA', nameview: 'SERVICIOS DE LAVANDERÍA', translate: 'FORM.OPTIONCATEGORYCURRENT.OPTION15' });
        optionsCategoryCurrents.push({ name: 'PAQUETE COMPLETO', nameview: 'PAQUETE COMPLETO', translate: 'FORM.OPTIONCATEGORYCURRENT.OPTION16' });
        optionsCategoryCurrents.push({ name: 'SERVICIOS ESPECIALIZADOS (SOLUCIONES TECNOLOGICAS COMO SOFTWARE, ASOCIACIONES DEL SECTOR, LOGÍSTICA, SISTEMAS DE INFORMACIÓN)', nameview: 'SERVICIOS ESPECIALIZADOS (SOLUCIONES TECNOLOGICAS COMO SOFTWARE, ASOCIACIONES DEL SECTOR, LOGÍSTICA, SISTEMAS DE INFORMACIÓN)', translate: 'FORM.OPTIONCATEGORYCURRENT.OPTION17' });



        let optionsCategoryCurrentsCOLMODA = [];
        optionsCategoryCurrentsCOLMODA.push({ name: 'PRENDAS DE PROTECCIÓN', nameview: 'PRENDAS DE PROTECCIÓN', translate: 'FORM.OPTIONCATEGORYCURRENTCOLMODA.OPTION1' });
        optionsCategoryCurrentsCOLMODA.push({ name: 'BIOSEGURIDAD', nameview: 'BIOSEGURIDAD', translate: 'FORM.OPTIONCATEGORYCURRENTCOLMODA.OPTION2' });
        optionsCategoryCurrentsCOLMODA.push({ name: 'JEANSWEAR', nameview: 'JEANSWEAR', translate: 'FORM.OPTIONCATEGORYCURRENTCOLMODA.OPTION3' });
        optionsCategoryCurrentsCOLMODA.push({ name: 'FORMAL/CASUAL', nameview: 'FORMAL/CASUAL', translate: 'FORM.OPTIONCATEGORYCURRENTCOLMODA.OPTION4' });
        optionsCategoryCurrentsCOLMODA.push({ name: 'ROPA INTERIOR', nameview: 'ROPA INTERIOR', translate: 'FORM.OPTIONCATEGORYCURRENTCOLMODA.OPTION5' });
        optionsCategoryCurrentsCOLMODA.push({ name: 'ROPA DE PLAYA', nameview: 'ROPA DE PLAYA', translate: 'FORM.OPTIONCATEGORYCURRENTCOLMODA.OPTION6' });
        optionsCategoryCurrentsCOLMODA.push({ name: 'ROPA DEPORTIVA', nameview: 'ROPA DEPORTIVA', translate: 'FORM.OPTIONCATEGORYCURRENTCOLMODA.OPTION7' });
        optionsCategoryCurrentsCOLMODA.push({ name: 'LOUNGEWEAR/PIJAMA', nameview: 'LOUNGEWEAR/PIJAMA', translate: 'FORM.OPTIONCATEGORYCURRENTCOLMODA.OPTION8' });
        optionsCategoryCurrentsCOLMODA.push({ name: 'PRENDAS DE CONTROL', nameview: 'PRENDAS DE CONTROL', translate: 'FORM.OPTIONCATEGORYCURRENTCOLMODA.OPTION9' });
        optionsCategoryCurrentsCOLMODA.push({ name: 'BISUTERIA', nameview: 'BISUTERIA', translate: 'FORM.OPTIONCATEGORYCURRENTCOLMODA.OPTION10' });
        optionsCategoryCurrentsCOLMODA.push({ name: 'JOYERIA', nameview: 'JOYERIA', translate: 'FORM.OPTIONCATEGORYCURRENTCOLMODA.OPTION11' });
        optionsCategoryCurrentsCOLMODA.push({ name: 'DOTACION', nameview: 'DOTACION', translate: 'FORM.OPTIONCATEGORYCURRENTCOLMODA.OPTION12' });
        optionsCategoryCurrentsCOLMODA.push({ name: 'CALZADO-MARROQUINERIA', nameview: 'CALZADO-MARROQUINERIA', translate: 'FORM.OPTIONCATEGORYCURRENTCOLMODA.OPTION13' });
        optionsCategoryCurrentsCOLMODA.push({ name: 'PAQUETE COMPLETO', nameview: 'PAQUETE COMPLETO', translate: 'FORM.OPTIONCATEGORYCURRENTCOLMODA.OPTION14' });



        this.setState({
            activities: activities,
            optionsSegmentOfInterests: optionsSegmentOfInterests,
            optionsCategoryCurrents: optionsCategoryCurrents,
            optionsCategoryCurrentsCOLMODA: optionsCategoryCurrentsCOLMODA
        });


        this.getCategories();

    }

    getCategories = () => {
        _service.getCategories()
            .then(resp => {
                console.log(resp);
                this.setState({
                    categories: resp
                });
            })
            .catch(error => {
            })
    }


    handleChange = (props, event) => {
        const { value, name } = event.target;
        if (name === props) {
            if (!value) {
                let datAerror = { errors: { ...this.state.errors }, assistant: { ...this.state.assistant } };
                datAerror.errors[props] = true;
                datAerror.assistant[props] = "";
                this.setState({
                    errors: datAerror.errors,
                    assistant: datAerror.assistant
                });
            } else {
                let datAerror = { errors: { ...this.state.errors }, assistant: { ...this.state.assistant } };
                datAerror.errors[props] = false;
                datAerror.assistant[props] = value;
                this.setState({
                    errors: datAerror.errors,
                    assistant: datAerror.assistant
                });
            }
        }
    }

    handleChangeoptionsCategoryCurrents = (index, event) => {
        let optionsCategoryCurrents = this.state.optionsCategoryCurrents;
        optionsCategoryCurrents[index].check = event.target.checked;
        this.setState({
            optionsCategoryCurrents: optionsCategoryCurrents
        });
    }

    handleChangeother_interest_colmoda = (index, event) => {
        let optionsCategoryCurrentsCOLMODA = this.state.optionsCategoryCurrentsCOLMODA;
        optionsCategoryCurrentsCOLMODA[index].check = event.target.checked;
        this.setState({
            optionsCategoryCurrentsCOLMODA: optionsCategoryCurrentsCOLMODA
        });
    }


    save = async (e) => {
        e.preventDefault();
        this.setState({
            saving: true,
            messageLoader: "Guardando..."
        });




        let assistant = {
            ...this.props.assistant,
            ... this.state.assistant
        };


        if (this.props.field.documentnumber) {
            let document = await _service.findAssistantDocuement(this.state.assistant.documentnumber);
            if(document && document.type == 2){
                Swal.fire('Información','El numero documento ya existe en la base de datos','info');
                this.setState({
                    saving: false
                })
                return;
            }
         
        }


        let categoryId = 0;
        if (this.props.assistant.category) {
            categoryId = this.props.assistant.category.id;
        } else {
            categoryId = assistant.category;
        }

        if (this.props.field.interest) {
            let optionsCategoryCurrents = this.state.optionsCategoryCurrents;
            let interest = "";
            let filterSelected = optionsCategoryCurrents.filter(option => option.check);
            if (filterSelected.length > 0) {
                filterSelected.forEach(option => interest = interest + option.name + "_&&_");
            }
            assistant.interest = interest;
        }

        if (this.props.field.interest) {
            let optionsCategoryCurrentsCOLMODA = this.state.optionsCategoryCurrentsCOLMODA;
            let other_interest_colmoda = "";
            let filterSelected = optionsCategoryCurrentsCOLMODA.filter(option => option.check);
            if (filterSelected.length > 0) {
                filterSelected.forEach(option => other_interest_colmoda = other_interest_colmoda + option.name + "_&&_");
            }
            assistant.other_interest_colmoda = other_interest_colmoda;
        }

        delete assistant.category;
        _service.updateAssistnat(categoryId, assistant)
            .then(response => {
                let listAssistent = [];
                listAssistent.push({ id: response.id, documentnumber: response.documentnumber });
                this.printAssitants(listAssistent);

            })
            .catch(err => this.setState({ saving: false }));
    }


    printAssitants = (listAssistent) => {
        this.setState({
            messageLoader: " Guardando Impresion..."
        })
        _service.printAssitants(listAssistent)
            .then(response => {
                this.printer(response);
            })
            .catch(err => this.setState({ saving: false }))
    }

    printer = (response) => {
        console.log("Entro aqui");
        var parameter = {
            nombre: response.assistants[0].name,
            apellido: response.assistants[0].lastName,
            empresa: response.assistants[0].company,
            pais: response.assistants[0].countryname,
            ciudad: response.assistants[0].cityname,
            categoria: response.assistants[0].category,
            codebar: response.assistants[0].codebar,
            cedula: response.assistants[0].documentnumber,
            email: response.assistants[0].email,
            id: response.assistants[0].codebar,
        };
        this.setState({
            messageLoader: "Imprimiendo escarapela..."
        });
        _service.imprimir(parameter)
            .then(response => {
                Swal.fire('Información', 'El registro se imprimio correctamente', 'success')
                this.props.handleClose();
                this.setState({ saving: false });

            })
            .catch(err => this.setState({ saving: false }))
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    <Form name="FormAssistant" onSubmit={this.save.bind(this)}>

                        {
                            this.props.field.documentnumber &&
                            <Form.Group className="mb-3 control-form    " controlId="documentnumber">
                                <Form.Label>Cedula <span className="text-danger">*</span></Form.Label>
                                <Form.Control required name="documentnumber" id="name" className="form-control" type="text" placeholder="Ingrese la cedula"
                                    value={this.state.assistant["documentnumber"]}
                                    onChange={this.handleChange.bind(this, "documentnumber")}
                                />
                                {
                                    this.state.errors["documentnumber"] ? (<Form.Text className='error-field'>
                                        * La cedula es requerido
                                    </Form.Text >) : (<div>

                                    </div>)
                                }
                            </Form.Group>
                        }



                        {
                            this.props.field.name &&
                            <Form.Group className="mb-3 control-form    " controlId="name">
                                <Form.Label>Nommbre <span className="text-danger">*</span></Form.Label>
                                <Form.Control required name="name" id="name" className="form-control" type="text" placeholder="Ingrese el nombre"
                                    value={this.state.assistant["name"]}
                                    onChange={this.handleChange.bind(this, "name")}
                                />
                                {
                                    this.state.errors["name"] ? (<Form.Text className='error-field'>
                                        * El nombre es requerido
                                    </Form.Text >) : (<div>

                                    </div>)
                                }
                            </Form.Group>
                        }



                        {
                            this.props.field.lastName &&
                            <Form.Group className="mb-3 control-form    " controlId="lastName">
                                <Form.Label>Apellido <span className="text-danger">*</span></Form.Label>
                                <Form.Control required name="lastName" id="lastName" className="form-control" type="text" placeholder="Ingrese el Apellido"
                                    value={this.state.assistant["lastName"]}
                                    onChange={this.handleChange.bind(this, "lastName")}
                                />
                                {
                                    this.state.errors["lastName"] ? (<Form.Text className='error-field'>
                                        * El Apellido es requerido
                                    </Form.Text >) : (<div>

                                    </div>)
                                }
                            </Form.Group>
                        }



                        {
                            this.props.field.company &&
                            <Form.Group className="mb-3 control-form    " controlId="company">
                                <Form.Label>Empresa <span className="text-danger">*</span></Form.Label>
                                <Form.Control required name="company" id="company" className="form-control" type="text" placeholder="Ingrese el Empresa"
                                    value={this.state.assistant["company"]}
                                    onChange={this.handleChange.bind(this, "company")}
                                />
                                {
                                    this.state.errors["company"] ? (<Form.Text className='error-field'>
                                        * El Empresa es requerido
                                    </Form.Text >) : (<div>

                                    </div>)
                                }
                            </Form.Group>
                        }



                        {
                            this.props.field.email &&
                            <Form.Group className="mb-3 control-form    " controlId="email">
                                <Form.Label>Email <span className="text-danger">*</span></Form.Label>
                                <Form.Control required name="email" id="email" className="form-control" type="text" placeholder="Ingrese el Email"
                                    value={this.state.assistant["email"]}
                                    onChange={this.handleChange.bind(this, "email")}
                                />
                                {
                                    this.state.errors["email"] ? (<Form.Text className='error-field'>
                                        * El Email es requerido
                                    </Form.Text >) : (<div>

                                    </div>)
                                }
                            </Form.Group>
                        }


                        {
                            this.props.field.countryname &&
                            <Form.Group className="mb-3 control-form    " controlId="countryname">
                                <Form.Label>Pais <span className="text-danger">*</span></Form.Label>
                                <Form.Control required name="countryname" id="countryname" className="form-control" type="text" placeholder="Ingrese el Pais"
                                    value={this.state.assistant["countryname"]}
                                    onChange={this.handleChange.bind(this, "countryname")}
                                />
                                {
                                    this.state.errors["countryname"] ? (<Form.Text className='error-field'>
                                        * El Pais es requerido
                                    </Form.Text >) : (<div>

                                    </div>)
                                }
                            </Form.Group>
                        }


                        {
                            this.props.field.department &&
                            <Form.Group className="mb-3 control-form    " controlId="department">
                                <Form.Label>Departamento <span className="text-danger">*</span></Form.Label>
                                <Form.Control required name="department" id="department" className="form-control" type="text" placeholder="Ingrese el Departamento"
                                    value={this.state.assistant["department"]}
                                    onChange={this.handleChange.bind(this, "department")}
                                />
                                {
                                    this.state.errors["department"] ? (<Form.Text className='error-field'>
                                        * El Departamento es requerido
                                    </Form.Text >) : (<div>

                                    </div>)
                                }
                            </Form.Group>
                        }

                        {
                            this.props.field.cityname &&
                            <Form.Group className="mb-3 control-form    " controlId="cityname">
                                <Form.Label>Ciudad <span className="text-danger">*</span></Form.Label>
                                <Form.Control required name="cityname" id="cityname" className="form-control" type="text" placeholder="Ingrese el Ciudad"
                                    value={this.state.assistant["cityname"]}
                                    onChange={this.handleChange.bind(this, "cityname")}
                                />
                                {
                                    this.state.errors["cityname"] ? (<Form.Text className='error-field'>
                                        * El Ciudad es requerido
                                    </Form.Text >) : (<div>

                                    </div>)
                                }
                            </Form.Group>
                        }


                        {
                            this.props.field.category &&
                            <Form.Group className="mb-3 control-form    " controlId="category">
                                <Form.Label>Categoria <span className="text-danger">*</span></Form.Label>
                                <Form.Select required name="category" id="category" className="form-control" type="text" placeholder="Ingrese el Categoria"
                                    value={this.state.assistant["category"]}
                                    onChange={this.handleChange.bind(this, "category")}
                                >

                                    <option>Seleccione la Categoria</option>
                                    {
                                        this.state.categories.map((option, index) => {
                                            return <option key={index + 'categories'} value={option.id}>{option.name}</option>
                                        })
                                    }

                                </Form.Select>



                                {
                                    this.state.errors["category"] ? (<Form.Text className='error-field'>
                                        * El Categoria es requerido
                                    </Form.Text >) : (<div>

                                    </div>)
                                }
                            </Form.Group>
                        }



                        {
                            this.props.field.tel2 &&
                            <Form.Group className="mb-3 control-form" controlId="tel2">
                                <Form.Label>Celular <span className="text-danger">*</span></Form.Label>
                                <Form.Control required name="tel2" id="tel2" className="form-control" type="text" placeholder="Ingrese el Celular"
                                    value={this.state.assistant["tel2"]}
                                    onChange={this.handleChange.bind(this, "tel2")}
                                />
                                {
                                    this.state.errors["tel2"] ? (<Form.Text className='error-field'>
                                        * El Celular es requerido
                                    </Form.Text >) : (<div>
                                    </div>)
                                }
                            </Form.Group>
                        }



                        {
                            this.props.field.activity &&
                            <Form.Group className="mb-3 control-form    " controlId="activity">
                                <Form.Label>Actividad Comercial <span className="text-danger">*</span></Form.Label>
                                <Form.Select required name="activity" id="activity" className="form-control" type="text" placeholder="Ingrese el Actividad Comercial"
                                    value={this.state.assistant["activity"]}
                                    onChange={this.handleChange.bind(this, "activity")}
                                >
                                    <option>Seleccione la Actividad Comercial</option>
                                    {
                                        this.state.activities.map((option, index) => {
                                            return <option key={index} value={option.name}>{option.name}</option>
                                        })
                                    }
                                </Form.Select>
                                {
                                    this.state.errors["activity"] ? (<Form.Text className='error-field'>
                                        * El Actividad Comercial es requerido
                                    </Form.Text >) : (<div>
                                    </div>)
                                }
                            </Form.Group>
                        }





                        {
                            this.props.field.interestSegment &&
                            <Form.Group className="mb-3 control-form    " controlId="interestSegment">
                                <Form.Label>Segmento de mercado de interés <span className="text-danger">*</span></Form.Label>
                                <Form.Select required name="interestSegment" id="interestSegment" className="form-control" type="text" placeholder="Ingrese el Segmento de mercado de interés"
                                    value={this.state.assistant["interestSegment"]}
                                    onChange={this.handleChange.bind(this, "interestSegment")}
                                >
                                    <option>Seleccione Segmento de mercado de interés </option>
                                    {
                                        this.state.optionsSegmentOfInterests.map((option, index) => {
                                            return <option key={index + 'segment'} value={option.name}>{option.name}</option>
                                        })
                                    }
                                </Form.Select>
                                {
                                    this.state.errors["interestSegment"] ? (<Form.Text className='error-field'>
                                        * El Segmento de mercado de interés es requerido
                                    </Form.Text >) : (<div>
                                    </div>)
                                }
                            </Form.Group>
                        }




                        {
                            this.props.field.type_event &&
                            <Form.Group className="mb-3 control-form    " controlId="type_event">
                                <Form.Label>Feria en la que deseas participar <span className="text-danger">*</span></Form.Label>
                                <Form.Select required name="type_event" id="type_event" className="form-control" type="text" placeholder="Ingrese el Feria en la que deseas participar"
                                    value={this.state.assistant["type_event"]}
                                    onChange={this.handleChange.bind(this, "type_event")}
                                >
                                    <option>Seleccione la Feria en la que deseas participar</option>
                                    <option value="Colombiatex">Colombiatex</option>
                                    <option value="Colombiamoda">Colombiamoda</option>
                                    <option value="Ambos">Ambos</option>

                                </Form.Select>
                                {
                                    this.state.errors["type_event"] ? (<Form.Text className='error-field'>
                                        * El Feria en la que deseas participar es requerido
                                    </Form.Text >) : (<div>
                                    </div>)
                                }
                            </Form.Group>
                        }

                        {
                            (this.props.field.current
                                && (this.state.assistant.type_event === 'Colombiatex'
                                    || this.state.assistant.type_event === 'Ambos'
                                    || this.props.assistant.type_event === 'Colombiatex'
                                    || this.props.assistant.type_event === 'Ambos'
                                )

                            ) &&
                            <Form.Group className="mb-3 control-form    " controlId="current">
                                <Form.Label>Categoría principal de interés Colombiatex <span className="text-danger">*</span></Form.Label>
                                <Form.Select required name="current" id="current" className="form-control" type="text" placeholder="Ingrese el Categoría principal de interés Colombiatex"
                                    value={this.state.assistant["current"]}
                                    onChange={this.handleChange.bind(this, "current")}
                                >

                                    <option>Seleccione la Categoría principal de interés Colombiatexl</option>
                                    {
                                        this.state.optionsCategoryCurrents.map((option, index) => {
                                            return <option key={index + 'optionsCategoryCurrents'} value={option.name}>{option.name}</option>
                                        })
                                    }

                                </Form.Select>
                                {
                                    this.state.errors["current"] ? (<Form.Text className='error-field'>
                                        * El Categoría principal de interés Colombiatex es requerido
                                    </Form.Text >) : (<div>
                                    </div>)
                                }
                            </Form.Group>
                        }


                        {
                            (this.props.field.interest
                                && (this.state.assistant.type_event === 'Colombiatex'
                                    || this.state.assistant.type_event === 'Ambos'
                                    || this.props.assistant.type_event === 'Colombiatex'
                                    || this.props.assistant.type_event === 'Ambos'
                                )
                            ) &&
                            <Form.Group className="mb-3 control-form    " controlId="interest">
                                <Form.Label>Otras categorías de interés Colombiatex <span className="text-danger">*</span></Form.Label>
                                {/* <Form.Control required name="interest" id="interest" className="form-control" type="text" placeholder="Ingrese el Otras categorías de interés Colombiatex"
                                    value={this.state.assistant["interest"]}
                                    onChange={this.handleChange.bind(this, "interest")}
                                /> */}

                                <div className="row">
                                    {
                                        this.state.optionsCategoryCurrents.map((option, index) => {
                                            return <div className="col-md-12"> <Form.Check
                                                type='checkbox'
                                                key={`optionsCategoryCurrents-option-${index}`}
                                                id={`optionsCategoryCurrents-option-${index}`}
                                                label={option.name}
                                                value={option.check}
                                                onChange={this.handleChangeoptionsCategoryCurrents.bind(this, index)}
                                            />
                                            </div>
                                        })
                                    }
                                </div>

                                {
                                    this.state.errors["interest"] ? (<Form.Text className='error-field'>
                                        * El Otras categorías de interés Colombiatex es requerido
                                    </Form.Text >) : (<div>
                                    </div>)
                                }
                            </Form.Group>
                        }


                        {
                            (this.props.field.interest_colmoda
                                && (this.state.assistant.type_event === 'Colombiamoda'
                                    || this.state.assistant.type_event === 'Ambos'
                                    || this.props.assistant.type_event === 'Colombiamoda'
                                    || this.props.assistant.type_event === 'Ambos'
                                )


                            ) &&
                            <Form.Group className="mb-3 control-form     " controlId="interest_colmoda">
                                <Form.Label>Categoría principal de interés Colombiamoda <span className="text-danger">*</span></Form.Label>
                                <Form.Select required name="interest_colmoda" id="interest_colmoda" className="form-control" type="text" placeholder="Ingrese el Categoría principal de interés Colombiamoda"
                                    value={this.state.assistant["interest_colmoda"]}
                                    onChange={this.handleChange.bind(this, "interest_colmoda")}
                                >
                                    <option>Select Categoría principal de interés Colombiamoda </option>
                                    {
                                        this.state.optionsCategoryCurrentsCOLMODA.map((option, index) => {
                                            return <option key={index + 'interest_colmoda'} value={option.name}>{option.name}</option>
                                        })
                                    }

                                </Form.Select>
                                {
                                    this.state.errors["interest_colmoda"] ? (<Form.Text className='error-field'>
                                        * El Categoría principal de interés Colombiamoda es requerido
                                    </Form.Text >) : (<div>
                                    </div>)
                                }
                            </Form.Group>
                        }



                        {
                            (this.props.field.other_interest_colmoda
                                && (this.state.assistant.type_event === 'Colombiamoda'
                                    || this.state.assistant.type_event === 'Ambos'
                                    || this.props.assistant.type_event === 'Colombiamoda'
                                    || this.props.assistant.type_event === 'Ambos'
                                )
                            ) &&
                            <Form.Group className="mb-3 control-form    " controlId="other_interest_colmoda">
                                <Form.Label>Otras categorías de interés Colombiamoda <span className="text-danger">*</span></Form.Label>
                                {/* <Form.Control required name="other_interest_colmoda" id="other_interest_colmoda" className="form-control" type="text" placeholder="Ingrese el Otras categorías de interés Colombiamoda"
                                    value={this.state.assistant["other_interest_colmoda"]}
                                    onChange={this.handleChange.bind(this, "other_interest_colmoda")}
                                /> */}

                                <div className="row">
                                    {
                                        this.state.optionsCategoryCurrentsCOLMODA.map((option, index) => {
                                            return <div className="col-md-12"> <Form.Check
                                                type='checkbox'
                                                key={`other_interest_colmoda-option-${index}`}
                                                id={`other_interest_colmoda-option-${index}`}
                                                label={option.name}
                                                value={option.check}
                                                onChange={this.handleChangeother_interest_colmoda.bind(this, index)}
                                            />
                                            </div>
                                        })
                                    }
                                </div>
                                {
                                    this.state.errors["other_interest_colmoda"] ? (<Form.Text className='error-field'>
                                        * El Otras categorías de interés Colombiamoda es requerido
                                    </Form.Text >) : (<div>
                                    </div>)
                                }
                            </Form.Group>
                        }


                        {
                            !this.state.saving
                                ?

                                <Button variant="primary" type="submit">
                                    Guardar cambios
                                </Button>
                                : <div className="text-center">
                                    {this.state.messageLoader}
                                </div>
                        }

                    </Form>
                </div>

            </div>
        )
    }

}