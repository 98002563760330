import { Component } from "react";
import './tabs.css';
import {
    Outlet
} from 'react-router-dom';
import _auth from '../../service/auth';


export default class Tabs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            changeLeng: true,
            close: true
        }
    }
    qrNatovation = () => {
        this.props.navigate('tabs/qr');
    }

    assistantNatovation = () => {
        this.props.navigate('tabs/assistant');
    }

    configServer = () => {
        this.props.navigate('tabs/config');
    }


    close = () => {
        _auth.logaunt(() => {
            this.props.navigate('login');
            this.setState({
                close: false
            });
            localStorage.clear();
        });
    }



    render() {
        let cssConter = "contentview";
        return (
            <div className="row">
                <div className="fondo-tabs"></div>
                <header>
                    <div>
                        <img src="https://incontactostorageprod.blob.core.windows.net/subirm/16560037881271656003788127.png" alt="Logo Feriale" />
                    </div>
                    <div>
                        <button className="btn-personalization-transparente" onClick={this.close.bind()}  >
                            <i className="fa-solid fa-user-lock"></i>
                            <br />
                        </button>

                    </div>
                </header>

                <div className={'p-4 ' + cssConter} >
                    <Outlet />
                </div>


                <footer>
                    <button className="btn-personalization-transparente" onClick={this.assistantNatovation}  >
                        <i className="fa-solid fa-user-group"></i>
                        <br />
                        Asistentes</button>
                    <button className="btn-personalization-transparente" onClick={this.qrNatovation} >
                        <i className="fa-solid fa-qrcode"></i>
                        <br />
                        Lectura
                    </button>

                    <button className="btn-personalization-transparente" onClick={this.configServer} >
                        <i className="fa-solid fa fa-cog"></i>
                        <br />
                        Config
                    </button>

                </footer>


            </div>
        )
    }
}