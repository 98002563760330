import { Component } from "react";
import { Form, Button } from 'react-bootstrap';
import Swal from "sweetalert2";
import _service from '../../service/service';

export default class ConfigEvent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            messageLoader: 'Guarando...',
            data: {
                ipEspejo: 'http://192.168.0.192'
            },
            errors: {
                ipEspejo: false
            },
            printing: false,
            printers: [],
            servers: [
                {
                    name: 'Servidor principal (Google)',
                    url: 'https://coltex.aplicacionesincontacto.com/inexmodapi'
                },
                {
                    name: 'Servidor Mercury',
                    url: 'https://coltex1.aplicacionesincontacto.com/inexmodapi'
                },
                {
                    name: 'Servidor 2 (Zeus)',
                    url: 'https://aplicacionesincontacto.com:48080/inexmodapi'
                }
                // ,
                // {
                //     name: 'Inexmoda Pruebas',
                //     url: 'https://aplicacionesincontacto.com:48080/inexmodapitest'
                // }
            ]
        }

    }

    init() {
        let ipEspejo = _service.getIpEspejo();
        let print = _service.getPrinter();
        let ipserver = _service.getIpServer();
        if (ipEspejo) {
            this.setState({
                data: {
                    ipEspejo: ipEspejo,
                    print: print,
                    ipserver: ipserver
                }
            });

            this.getPrinters2();
        }else{
            this.setState({
                data: {
                    ipEspejo: 'https://10.0.11.0:8443',
                    ipserver: ipserver
                }
            });

        }
    }

    componentDidMount() {
        this.init();
    }

    handleChange = (props, event) => {
        const { value, name } = event.target;
        if (name === props) {
            if (!value) {
                let datAerror = { errors: { ...this.state.errors }, assistant: { ...this.state.data } };
                datAerror.errors[props] = true;
                datAerror.data[props] = "";
                this.setState({
                    errors: datAerror.errors,
                    data: datAerror.data
                });
            } else {
                let datAerror = { errors: { ...this.state.errors }, data: { ...this.state.data } };
                datAerror.errors[props] = false;
                datAerror.data[props] = value;
                this.setState({
                    errors: datAerror.errors,
                    data: datAerror.data
                });
            }
        }
    }

    openLink() {
        if (!this.state.data.ipEspejo) {
            return;
        }
        window.open(this.state.data.ipEspejo, '_new');
    }


    save = async (e) => {
        e.preventDefault();
        localStorage.setItem('Printer', this.state.data.print);
        localStorage.setItem('IpEspejo', this.state.data.ipEspejo);
        localStorage.setItem('ipserver', this.state.data.ipserver);

        Swal.fire('Información', 'Se guardo la configuracion', 'success');
    }

    getPrinters2 = () => {
        this.setState({
            printing: true
        })
        _service.getPrinters()
            .then((response) => {
                let printers = [];
                let printer = _service.getPrinter();
                response.prints.forEach((element, index) => {
                    if (printer == element.name) {
                        //   indexPrinter = index;
                    }
                    printers.push(element.name);
                });

                this.setState({
                    printing: false,
                    printers: printers
                })
            }).catch(error => {
                this.setState({
                    printing: false
                })

            })
    }


    getPrinters = () => {
        if (this.state.data.ipEspejo)
            localStorage.setItem('IpEspejo', this.state.data.ipEspejo);
        else
            return;

        this.setState({
            printing: true
        })
        _service.getPrinters()
            .then((response) => {
                let printers = [];
                let printer = _service.getPrinter();
                response.prints.forEach((element, index) => {
                    if (printer == element.name) {
                        //   indexPrinter = index;
                    }
                    printers.push(element.name);
                });

                this.setState({
                    printing: false,
                    printers: printers
                })
            }).catch(error => {
                this.setState({
                    printing: false
                })

            })
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-6 offset-md-3">

                    <div className="card card-body">

                        <Form name="FormConfig" onSubmit={this.save.bind(this)}>

                            <Form.Group className="mb-3 control-form    " controlId="ipserver">
                                <Form.Label>Selecciona eo servidor de donde se cargaran la información de los asistentes<span className="text-danger">*</span></Form.Label>
                                <Form.Select required name="ipserver" id="ipserver" className="form-control" type="text" placeholder="Ingrese la ip Espejo"
                                    value={this.state.data["ipserver"]}
                                    onChange={this.handleChange.bind(this, "ipserver")}
                                >
                                    <option></option>
                                    {
                                        this.state.servers.map((item, index) => {
                                            return <option key={index + 'optionserver'} value={item.url}  >{item.name}</option>;
                                        })
                                    }
                                </Form.Select>
                                {
                                    this.state.errors["ipserver"] ? (<Form.Text className='error-field'>
                                        * La ipEspejo del servidor espejo  requerido
                                    </Form.Text >) : (<div>

                                    </div>)
                                }
                            </Form.Group>




                            <Form.Group className="mb-3 control-form    " controlId="ipEspejo">
                                <Form.Label>Ingrese la ipEspejo del servidor espejo <span className="text-danger">*</span></Form.Label>
                                <div className="buttinlink">
                                    <Form.Control required name="ipEspejo" id="ipEspejo" className="form-control" type="text" placeholder="Ingrese la ip Espejo"
                                        value={this.state.data["ipEspejo"]}
                                        onChange={this.handleChange.bind(this, "ipEspejo")}
                                    />
                                    <Button variant="primary" type="button" size="sm" onClick={this.openLink.bind(this)}>
                                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                    </Button>
                                </div>

                                {
                                    this.state.errors["ipEspejo"] ? (<Form.Text className='error-field'>
                                        * La ipEspejo del servidor espejo  requerido
                                    </Form.Text >) : (<div>

                                    </div>)
                                }


                            </Form.Group>

                            <div className="row mb-4">
                                <div className="col-md-12">
                                    {
                                        !this.state.printing
                                            ?

                                            <Button variant="primary" type="button" onClick={this.getPrinters.bind(this)}>
                                                Cargar impresoras
                                            </Button>
                                            : <div className="text-center">
                                                Cargando impresores
                                            </div>
                                    }
                                </div>
                            </div>

                            {
                                !this.state.printing
                                &&

                                <Form.Group className="mb-3 control-form    " controlId="print">
                                    <Form.Label>Seleccione la impresora <span className="text-danger">*</span></Form.Label>
                                    <Form.Select required name="print" id="print" className="form-control" type="text" placeholder="Ingrese la ip Espejo"
                                        value={this.state.data["print"]}
                                        onChange={this.handleChange.bind(this, "print")}
                                    >
                                        <option>Seleccione las impresoras</option>
                                        {
                                            this.state.printers.map((o, index) => {
                                                return <option key={index + 'option'} value={o}>{o}</option>
                                            })
                                        }

                                    </Form.Select>
                                    {
                                        this.state.errors["print"] ? (<Form.Text className='error-field'>
                                            * La Impresora es requerida
                                        </Form.Text >) : (<div>

                                        </div>)
                                    }
                                </Form.Group>


                            }



                            {
                                !this.state.saving
                                    ?

                                    <Button variant="primary" type="submit">
                                        Guardar cambios
                                    </Button>
                                    : <div className="text-center">
                                        {this.state.messageLoader}
                                    </div>
                            }

                        </Form>
                    </div>
                </div>
            </div>
        )
    }
}