import React, { Component } from 'react';
import QrReader from 'react-qr-reader';
import Swal from 'sweetalert2';
import _service from '../service/service';
import ModalAssistant from './assistant/modal.assistant';


export default class Qr extends Component {

    constructor(props) {
        super(props);
        this.state = {
            typeView: 2,
            valueEnter: "",
            showModalAssistant: false,
            assistantSelected: null,
            zona: 'Principal',
            searching: false,
            noLectura: false
        }
    }

    handleScan = (data) => {
        console.log(data);
        if (data && !this.state.noLectura) {
            this.setState({ valueEnter: data, noLectura: true });
            this.validateAccess(data);
        }
    }

    changeQR = () => {
        this.setState({
            scannerComplete: true,
            text_zone: '',
            messageError: '',
            verifingAccess: false
        });
    }

    handleError = (err) => {
        console.error(err)
    }


    choiceButton = () => {
        if (this.state.facingMode === "environment") {
            this.setState({
                facingMode: "user"
            })
        } else {
            this.setState({
                facingMode: "environment"
            })
        }
    }


    getViewParticpant = (response) => {
        this.setState({
            showModalAssistant: true,
            assistantSelected: response,
            valueEnter: ''
        });
    }

    changeValue = (event) => {
        const { value } = event.target;
        if (value) {
            let valuereturn = JSON.parse(value);
            this.setState({
                selectedZone: JSON.stringify(valuereturn),
                selectedZoneObject: valuereturn
            });
        } else {
            this.setState({
                selectedZone: "",
                selectedZoneObject: null
            });
        }

    }

    changeValurIdentification = (event) => {
        const { value } = event.target;
        if (value) {
            this.setState({
                valueEnter: value
            });
        } else {
            this.setState({
                valueEnter: ""
            });
        }
    }

    clickType = (type) => {
        console.log(type);

        this.setState({
            typeView: type
        })
    }

    changeKeyBoard = () => {
        this.setState({
            typeKeyKeyboard: (this.state.typeKeyKeyboard === 1 ? 2 : 1)
        })
    }


    onKeyUp = (event) => {
        if (event.charCode === 13) {
            this.validateAccess();
        }
    }

    getValidationDocument(documents) {
        return new Promise((resolve, reject) => {
            _service.getdocuments(documents)
                .then(resp => {
                    return resolve(resp);
                })
                .catch(erro => {
                    reject(Error)
                })

        })
    }

    validateAccess = async (data = "") => {

        let value = this.state.valueEnter;
        if (data) {
            value = data;
        }


        if (!value) {
            Swal.fire('Información', 'Debe ungresar un valor para continuar', 'info');
            return;
        }

        this.setState({
            searching: true
        });


        _service.findAssistantByEventUser(value)
            .then(async (resp) => {

                Swal.fire({
                    html: 'Verificando...',
                    showConfirmButton: false,
                    showCancelButton: false,
                    showLoaderOnConfirm: false,
                    focusCancel: false,
                    backdrop: false,
                    allowEscapeKey: false,
                    allowOutsideClick: false
                });

                let documents = await this.getValidationDocument(value);
                Swal.close();
                if (documents.length > 0) {
                    let documentsSend = documents.map(f => f.document);
                    let message = "Los siguientes documetos  " + documentsSend.toString() + " No puede ser impreso ya que se encuentra en lista negra";
                    Swal.fire('Información', message, 'info');
                    this.setState({
                        searching: false
                    });
                    return;
                }

                //	3 deshabilitado
                //	4 cambio 
                //		2 ya existe 
                //		1 no existe
                console.log(resp);
                if (resp && resp.status) {
                    let fullName = '';
                    if (resp.name) {
                        fullName = resp.name;
                    }
                    if (resp.lastName) {
                        fullName = fullName + " " + resp.lastName;
                    }

                    if (resp.status === "RECHAZADO" || resp.status === "RECHAZADA") {
                        if (this.state.zona === "Principal") {
                            if (resp.suggested) {
                                Swal.fire('Información', "El participante " + fullName + " es un comprador sugerido de Inexmoda no lo ha aprobado, el asistente puede pagar la entrada o comunicarse con el expositor que lo sugirió para que se contacte con la persona encargada de Inexmoda", 'info');
                            } else {
                                Swal.fire('Información', "El participante " + fullName + " se encuentra pendiente de pago, deberá dirigirse a los puntos de pago.", 'info');
                            }
                        } else {
                            if (resp.suggested) {
                                Swal.fire('Información', "Señor(a) eres un comprador sugerido de Inexmoda no lo ha aprobado, el asistente puede pagar la entrada o comunicarse con el expositor que lo sugirió para que se contacte con la persona encargada de Inexmoda", 'info');
                            } else {
                                Swal.fire('Información', "Señor(a): su registro se encuentra pendiente de pago, deberá dirigirse a los puntos de pago.", 'info');
                            }

                        }
                    } else if (resp.status === "PENDIENTE") {
                        if (this.state.zona === "Principal") {
                            if (resp.suggested) {
                                Swal.fire('Información', "El participante " + fullName + " es un comprador sugerido de Inexmoda no lo ha aprobado, el asistente puede pagar la entrada o comunicarse con el expositor que lo sugirió para que se contacte con la persona encargada de Inexmoda", 'info');
                            } else {
                                Swal.fire('Información', "El participante " + fullName + " se encuentra pendiente de pago, deberá dirigirse a los puntos de pago.", 'info');
                            }

                        } else {
                            if (resp.suggested) {
                                Swal.fire('Información', "Señor(a): eres un comprador sugerido de Inexmoda no lo ha aprobado, el asistente puede pagar la entrada o comunicarse con el expositor que lo sugirió para que se contacte con la persona encargada de Inexmoda", 'info');
                            } else {
                                Swal.fire('Información', "Señor(a): su registro se encuentra pendiente de pago, deberá dirigirse a los puntos de pago.", 'info');
                            }
                        }
                    }
                }

                if (resp.error === 1) {
                    Swal.fire('Información', "El documento (" + value + ")  no se encuentra registrado. Ingrese  www.registrocolombiatext.com y realice el registro", 'info');
                } else if (resp.error === 3) {
                    Swal.fire('Información', resp.message);
                } else if (resp.error === 2) {
                    let fullName = resp.name + " " + resp.lastName;

                    if (resp.status === "RECHAZADO" || resp.status === "RECHAZADA") {
                        if (this.state.zona === "Principal") {
                            if (resp.suggested) {
                                Swal.fire('Información', "El participante " + fullName + " es un comprador sugerido de Inexmoda no lo ha aprobado, el asistente puede pagar la entrada o comunicarse con el expositor que lo sugirió para que se contacte con la persona encargada de Inexmoda", 'info');
                            } else {
                                Swal.fire('Información', "El participante " + fullName + " se encuentra pendiente de pago, deberá dirigirse a los puntos de pago.", 'info');
                            }

                        } else {
                            if (resp.suggested) {
                                Swal.fire('Información', "Señor(a): eres un comprador sugerido de Inexmoda no lo ha aprobado, el asistente puede pagar la entrada o comunicarse con el expositor que lo sugirió para que se contacte con la persona encargada de Inexmoda", 'info');
                            } else {
                                Swal.fire('Información', "Señor(a): su registro se encuentra pendiente de pago, deberá dirigirse a los puntos de pago.", 'info');
                            }

                        }
                    } else if (resp.status === "PENDIENTE") {



                        if (this.state.zona === "Principal") {
                            if (resp.suggested) {
                                Swal.fire('Información', "El participante " + fullName + " es un comprador sugerido de Inexmoda no lo ha aprobado, el asistente puede pagar la entrada o comunicarse con el expositor que lo sugirió para que se contacte con la persona encargada de Inexmoda", 'info');
                            } else {
                                Swal.fire('Información', "El participante " + fullName + " se encuentra pendiente de pago, deberá dirigirse a los puntos de pago.", 'info');
                            }
                        } else {
                            if (resp.suggested) {
                                Swal.fire('Información', "Señor(a): eres un comprador sugerido de Inexmoda no lo ha aprobado, el asistente puede pagar la entrada o comunicarse con el expositor que lo sugirió para que se contacte con la persona encargada de Inexmoda", 'info');
                            } else {
                                Swal.fire('Información', "Señor(a): su registro se encuentra pendiente de pago, deberá dirigirse a los puntos de pago.", 'info');
                            }
                        }

                    } else {
                        if (resp.suggested) {
                            Swal.fire('Información', "El participante " + fullName + " es un comprador sugerido de Inexmoda no lo ha aprobado, el asistente puede pagar la entrada o comunicarse con el expositor que lo sugirió para que se contacte con la persona encargada de Inexmoda", 'info');
                        } else {
                            Swal.fire('Información', "Señor(a): no tenemos pago registrado, sidesea pagar dirigirse a los puntos de pago.", 'info');
                        }
                    }

                } else if (resp.error === 4) {
                    if (this.state.zona === "Principal") {
                        Swal.fire('Información', "Su escarapela no se encuentra habilitada, acérquese a los puntos de atención de Inexmoda ubicados en la carpa principal", 'info');
                    } else {
                        Swal.fire('Información', "Señor(a: Su escarapela no se encuentra habilitada, acérquese a los puntos de atención de Inexmoda ubicados en la carpa principal", 'info');

                    }
                }
                else {

                    if (this.state.zona === "Principal") {
                        this.getViewParticpant(resp);
                    } else {
                        if (resp.print) {


                            if (!resp.contact) {
                                resp.contact = "No registra";
                            }

                            if (!resp.phonecontact) {
                                resp.phonecontact = "No registra";
                            }

                            let contacto = resp.contact;
                            let contactoPhone = resp.phonecontact;
                            let fullName = resp.name + " " + resp.lastName;
                            Swal.fire('Información', "Señor(a): " + fullName + ", su escarapela ya se encuentra impresa y fue entregada a: " + contacto + " si desea comunicarse con quien la tiene el numero es: " + contactoPhone, 'info');
                        } else {
                            this.getViewParticpant(resp);
                        }
                    }


                }


                this.setState({
                    searching: false,
                    noLectura: false
                })

            })
            .catch(error => {

                this.setState({
                    searching: false,
                    noLectura: false
                })

            })
    }


    closeModal = (data) => {
        console.log(data);
        this.setState({
            showModalAssistant: data
        });
    }



    render() {
        const previewStyle = {
            height: 240,
            width: 320,
        }


        return (

            <div >

                {this.state.showModalAssistant && this.state.assistantSelected
                    ?
                    <ModalAssistant closeModal={(data) => this.closeModal(data)} show={this.state.showModalAssistant} assistant={this.state.assistantSelected}></ModalAssistant>
                    :
                    <div></div>}

                <div className="row">

                    <div className='col-md-4 offset-md-2 pt-2 pb-2'>
                        <button type='button' className='btn btn-danger text-white' onClick={() => this.clickType(1)} >Leer código</button>
                    </div>
                    <div className='col-md-4 pt-2 pb-2'>
                        <button type='button' className='btn btn-danger text-white' onClick={() => this.clickType(2)}  >Escribit ID</button>
                    </div>

                    {
                        this.state.typeView === 2 ?
                            (<><div className='col-md-8 offset-md-2'>
                                <div className="input-group has-validation">
                                    <input type={this.state.typeKeyKeyboard === 1 ? 'number' : "text"}
                                        onKeyPress={this.onKeyUp}
                                        onChange={this.changeValurIdentification}
                                        value={this.state.valueEnter}
                                        className='form-control form-control-lg form-control-text' />
                                    <span className="input-group-text form-control-border-group" id="inputGroupPrepend"
                                        onClick={this.changeKeyBoard}
                                    >
                                        {this.state.typeKeyKeyboard === 1 ? '123' : "ABC"}
                                    </span>
                                </div>
                            </div>
                                {
                                    this.state.searching ?

                                        <div className='text-center mt-5 alert alert-info' >
                                            Realizando busqueda...
                                        </div>
                                        :

                                        <div className='col-md-8 offset-md-2 mt-2'>
                                            <button type='button' className='btn btn-danger text-white' onClick={() => this.validateAccess()}  >Buscar</button>
                                        </div>
                                }
                            </>

                            )
                            :
                            (<></>)
                    }

                    {
                        this.state.typeView === 1 ?
                            (
                                <>
                                    <div className="col-md-12">
                                        <div className="center-component">
                                            <QrReader
                                                delay={this.state.delay}
                                                style={previewStyle}
                                                onError={this.handleError}
                                                onScan={this.handleScan}
                                                facingMode={this.state.facingMode}
                                                legacyMode={this.state.legacyMode}
                                                ref="qrReader"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-2 offset-md-5 mt-5 pt-5">
                                        <button type="button" className='btn btn-danger' onClick={this.choiceButton} >
                                            <i className="fa-solid fa-camera-rotate text-white" style={{ fontSize: '18px' }}></i>
                                        </button>
                                    </div>
                                </>
                            )
                            :
                            (<></>)
                    }

                    {
                        this.state.verifingAccess
                            ?
                            (<div className="col-md-6 offset-md-3 mt-5 pt-5 mb-5">
                                <div className="alert alert-info text-center">
                                    ...
                                </div>
                            </div>)
                            : (<></>)

                    }



                    {
                        (this.state.messageError || this.state.text_zone) && !this.state.verifingAccess ?
                            (<div className='col-md-8 offset-md-2 mt-5'>
                                <div className='card card-body'>
                                    <div className='row'>
                                        {
                                            this.state.messageError ?
                                                (
                                                    <div className="col-md-8 offset-md-2 text-danger-message">
                                                        <div dangerouslySetInnerHTML={{ __html: this.state.messageError }}></div>
                                                    </div>
                                                )
                                                : (<></>)
                                        }


                                        {
                                            this.state.text_zone ?
                                                (
                                                    <div className="col-md-8 offset-md-2">
                                                        <div dangerouslySetInnerHTML={{ __html: this.state.text_zone }}></div>
                                                    </div>
                                                )
                                                : (<></>)
                                        }

                                        {
                                            this.state.typeView === 1 ?

                                                (<div className='mt-3 col-md-4 offset-md-4'>
                                                    <button type="button" className='btn btn-danger text-white' onClick={this.changeQR} >
                                                        <i className="fa-solid fa-qrcode text-white" style={{ fontSize: '18px' }}></i>&nbsp;Leer otro
                                                    </button>
                                                </div>)

                                                : (<></>)
                                        }


                                    </div>


                                </div>
                            </div>)
                            : (<></>)

                    }

                </div>
            </div >
        )
    }
}